import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(to right, #00b09b, #96c93d);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  overflow: hidden;
`;

const Header = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
`;

const Title = styled.h2`
  color: #333;
  text-align: center;
  margin: 0;
`;

const MessageList = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  padding: 20px;
`;

const MessageItem = styled.div`
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
`;

const MessageName = styled.h3`
  color: #4a00e0;
  margin: 0 0 10px 0;
`;

const MessageContent = styled.p`
  color: #333;
  margin: 0;
`;

const DisplayMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get(
          "https://kiamabackend.mathenoid.com/messages"
        );
        setMessages(response.data.result);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
    const intervalId = setInterval(fetchMessages, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      <Card>
        <Header>
          <Title>Message Board</Title>
        </Header>
        <MessageList>
          {messages.length === 0 ? (
            <p style={{ textAlign: "center", color: "#6c757d" }}>
              No messages yet. Be the first to post!
            </p>
          ) : (
            messages.map((msg) => (
              <MessageItem key={msg.id}>
                <MessageName>{msg.name}</MessageName>
                <MessageContent>{msg.messages}</MessageContent>
              </MessageItem>
            ))
          )}
        </MessageList>
      </Card>
    </Container>
  );
};

export default DisplayMessages;
